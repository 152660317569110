export const Address = {

  getByZipCode: async (zipCode) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_COURIER}/courier/api/address/zipCode/${zipCode}`);

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  getList: async () => {
    try {
      const seller = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/${seller}/address`);

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  getAll: async (type) => {
    try {
      const seller = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/${seller}/address/${type}`);

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  create: async (data) => {
    try {

      const sellerId = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/${sellerId}/address`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  delete: async (addressId) => {
    try {

      const sellerId = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/${sellerId}/address/${addressId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

}