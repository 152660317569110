export const Seller = {

  login: async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  singup: async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/api/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      }); 

      return response.json();
    } catch(e) {
      console.log('e ', e);

      // {
      //   "courierCode": "MBE_FDX",
      //   "courierName": "FedEx",
      //   "serviceId": "FEDEX_STANDARD_OVERNIGHT_MBE",
      //   "typeService": "nextDay",
      //   "serviceName": "Standard Overnight",
      //   "deliveryDays": "1 día hab.",
      //   "price": 193.57,
      //   "bestOption": false
      // }

      return e;
    } 
  }

}