// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const es = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `aplicación`,
  user: `usuario`,
  list: `lista`,
  edit: `editar`,
  shop: `tienda`,
  blog: `blog`,
  post: `posteo`,
  mail: `emails`,
  email: `email`,
  password: `Contraseña`,
  emailLabel: `Dirección de Email`,
  forgotPassword: `¿Olvidaste la contraseña?`,
  login: `Ingresar`,
  remember: `Recordar`,
  chat: `chat`,
  cards: `tarjetas`,
  posts: `posteos`,
  create: `crear`,
  kanban: `kanban`,
  general: `general`,
  banking: `bancaria`,
  booking: `reservado`,
  profile: `perfil`,
  account: `cuenta`,
  product: `producto`,
  invoice: `factura`,
  details: `detalle`,
  checkout: `verificar`,
  calendar: `calendario`,
  analytics: `analisis`,
  ecommerce: `e-commerce`,
  management: `administración`,
  menu_level_1: `menú nivel 1`,
  menu_level_2: `menú nivel 2`,
  menu_level_3: `menú nivel 3`,
  item_disabled: `artículo desactivado`,
  item_label: `etiqueta del artículo`,
  item_caption: `subtítulo artículo`,
  description: `descripción`,
  other_cases: `otros casos`,
  item_by_roles: `artículo por roles`,
  only_admin_can_see_this_item: `Solo el admin puede ver esté artículo`,

  quote: 'cotizar envío',
  home: 'inicio',
  shipments: 'guías',
  orders: 'ordenes',
  pickups: 'recolecciones',
  clients: 'clientes',
  incidences: 'incidencias',
  balance: 'abono de saldo',
  configuration: 'configuración',
  configure: 'configurar',
  accounts: 'cuentas',
  agreement: 'convenios',
  store: 'tienda',
  address: 'direcciones',
  packages: 'embalaje',
  notifications: 'notificaciones',
  webhooks: 'webhooks',
  agreements: {
    home_title: 'Mis convenios',
    create: 'Agregar convenio',
  },
  stores: {
    home_title: 'Mis tiendas',
    create: 'Agregar tienda',
    available: 'Tiendas disponibles',
    url: 'Url de tienda',
    details: "Información de tienda"
  },
  form: {
    required: 'Campo obligatorio',
    invalid_format: 'Formato no válido',
    too_long: 'Texto muy largo. Max: ',
    too_short: 'Texto muy corto. Min: ',
  },
  contact: {
    phone: 'Teléfono de contacto',
    email: 'Correo electrónico de contacto',
  },
  address_dorm: {
    name: 'Título de la dirección',
    street_1: 'Calle y número',
    street_2: 'Complemtno de dirección',
    zipcode: 'Código Postal',
    neighborhood: 'Colonia',
    state: 'Estado',
    city: 'Ciudad',
    reference: 'Referencia',
  },
  save: "Guardar",
  status: "Estatus",
  service: "Servicio",
  label: "Etiqueta",
  labelId: "Guía",
  price: "Precio",
  courier: "Paquetería",
  labels: {
    plural: "Guías",
    created: "Guías generadas",
    created_on_period: "Guías generadas en el periodo",
    created_on_period_description: "Los estatus de las guías se actualiza dependendiendo del tipo de servicio"
  }
};

export default es;
