// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,

  quote: 'quote',
  home: 'home',
  shipments: 'shipments',
  orders: 'orders',
  pickups: 'pickups',
  clients: 'clients',
  incidences: 'incidences',
  balance: 'balance de saldo',
  configuration: 'configuration',
  configure: 'configure',
  store: 'store',
  accounts: 'accounts',
  agreement: 'agreement',
  address: 'address',
  packages: 'packages',
  notifications: 'notifications',
  webhooks: 'webhooks',
  agreements: {
    home_title: 'My agreements',
    create: 'Add agreement',
  },
  stores: {
    home_title: 'My stores',
    create: 'Add store',
    available: 'Available stores',
    url: 'Store url',
    details: "Store details"
  },
  form: {
    required: 'Required field',
    invalid_format: 'Invalid format',
    too_long: 'Text too long. Max: ',
    too_short: 'Text too short. Min: ',
  },
  contact: {
    phone: 'Contact phone',
    email: 'Contact email',
  },
  address_dorm: {
    name: 'Address name',
    street_1: 'Street 1',
    street_2: 'Street 2',
    zipcode: 'Zipcode',
    neighborhood: 'Neighborhood',
    state: 'State',
    city: 'City',
    reference: 'Reference',
  },
  save: "Save",
  status: "Status",
  service: "Service",
  label: "Label",
  labelId: "Label",
  price: "Price",
  courier: "Couries",
  labels: {
    plural: "Labels",
    created: "Created labels",
    created_on_period: "Created labels in the period",
    created_on_period_description: "The status of the guides is updated according to the type of service"
  }
};

export default en;
