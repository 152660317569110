export const Store = {

  getAll: async () => {
    try {
      const sellerId = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_STORE}/store/api/seller/${sellerId}/store`);

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  create: async (data) => {
    try {

      const sellerId = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_STORE}/store/api/seller/${sellerId}/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  delete: async (storeId) => {
    try {

      const sellerId = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_STORE}/store/api/seller/${sellerId}/store/${storeId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }                             
  },

  getOrders: async () => {
    try {

      const sellerId = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_STORE}/store/api/seller/${sellerId}/store/orders`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

}