export const Courier = {

  quote: async (data) => {
    try {
      const seller = localStorage.accessToken;
      data.seller = seller;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_COURIER}/courier/api/quote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  createShipment: async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_COURIER}/courier/api/shipment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  getAgreements: async () => {
    try {
      const seller = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_COURIER}/courier/api/agreement/seller/${seller}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  createAgreement: async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_COURIER}/courier/api/agreement`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  deleteAgreement: async (agreementId) => {
    try {
      const seller = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_COURIER}/courier/api/agreement/${agreementId}/seller/${seller}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

}