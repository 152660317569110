export const Boxes = {

  getAll: async () => {
    try {
      const seller = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/${seller}/boxes`);

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  create: async (data) => {
    try {

      const sellerId = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/${sellerId}/box`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

  delete: async (boxId) => {
    try {

      const sellerId = localStorage.accessToken;
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_SELLER}/seller/${sellerId}/box/${boxId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      return response.json();
    } catch(e) {
      console.log('e',e);

      return e
    }
  },

}